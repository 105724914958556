import { AccountAction } from '@/enums/AccountAction';
import { AccountTypes } from '@/enums/AccountTypes';
import { AccountActionOptions, AccountActionWithoutValueOptions } from '@/types/account';
import { defineStore } from 'pinia';
import { Ref, computed, reactive, ref } from 'vue';
import { useDelegateStore } from './delegate';
import { useUserStore } from './user';
import { Delegate } from '@/types/delegate';

export const useAccountStore = defineStore('account', () => {
  const userStore = useUserStore();
  const delegateStore = useDelegateStore();

  const isVerified = ref(false);
  const setIsVerified = () => (isVerified.value = true);
  const resetIsVerified = () => (isVerified.value = false);

  const isRevokeSearch = ref(false);
  const resetIsRevokeSearch = () => (isRevokeSearch.value = false);

  const isExecuting = ref(false);
  const resetIsExecuting = () => (isExecuting.value = false);

  const delegate = reactive<{ item: Partial<Delegate> }>({ item: {} });
  const setDelegate = (value: Delegate) => (delegate.item = value);
  const removeDelegate = () => (delegate.item = {});

  const identifier: Ref<string | null> = ref(null);
  const setIdentifier = (value: string | null) => (identifier.value = value);
  const getIdentifier = computed(() => identifier.value);
  const removeIdentifier = () => (identifier.value = null);

  const action: Ref<AccountAction | null> = ref(null);
  const resetAction = () => (action.value = null);

  const type: Ref<AccountTypes | null> = ref(null);
  const setType = (value: AccountTypes) => (type.value = value);
  const resetType = () => (type.value = null);

  const isEmail = computed(() => type.value === AccountTypes.EMAIL);
  const isPhone = computed(() => type.value === AccountTypes.PHONE);
  const isDelegates = computed(() => type.value === AccountTypes.DELEGATES);

  const isAddNew = computed(() => action.value === AccountAction.ADD_NEW_CONTACT);
  const isRemove = computed(() => action.value === AccountAction.REMOVE_CONTACT);
  const isNewPrimary = computed(() => action.value === AccountAction.NEW_PRIMARY);
  const isChangePrimary = computed(() => action.value === AccountAction.CHANGE_PRIMARY);
  const isEditPrimary = computed(() => action.value === AccountAction.EDIT_PRIMARY);
  const isSetAsPrimary = computed(() => action.value === AccountAction.SET_AS_PRIMARY);
  const isSeeSharedAccess = computed(() => action.value === AccountAction.SEE_SHARED_ACCESS);
  const isRevokeAccess = computed(() => action.value === AccountAction.REVOKE_ACCESS);
  const isDeleteAccount = computed(() => action.value === AccountAction.DELETE_ACCOUNT);

  const setAction = ({ value, identifier, type, delegate }: AccountActionOptions) => {
    if (typeof identifier !== 'undefined') setIdentifier(identifier);
    if (value) action.value = value;
    if (type) setType(type);
    if (delegate) setDelegate(delegate);
  };

  const addNew = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.ADD_NEW_CONTACT, ...options });
  };

  const removeContact = (delegate: Delegate, type: AccountTypes) =>
    setAction({
      value: AccountAction.REMOVE_CONTACT,
      type,
      delegate,
    });

  const newPrimary = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.NEW_PRIMARY, ...options });
  };

  const changePrimary = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.CHANGE_PRIMARY, ...options });
  };

  const editPrimary = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.EDIT_PRIMARY, ...options });
  };

  const setAsPrimary = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.SET_AS_PRIMARY, ...options });
  };

  const seeSharedAccess = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.SEE_SHARED_ACCESS, ...options });
  };

  const revokeAccess = (delegate: Delegate, { type, isSearch }: { type: AccountTypes; isSearch: boolean }) => {
    setAction({
      value: AccountAction.REVOKE_ACCESS,
      type,
      delegate,
    });

    if (isSearch) isRevokeSearch.value = isSearch;
  };

  const deleteAccount = (options?: AccountActionWithoutValueOptions) => {
    setAction({ value: AccountAction.DELETE_ACCOUNT, ...options });
  };

  const execute = async () => {
    isExecuting.value = true;

    if (isPhone.value) {
      if (isRemove.value && delegate?.item?.id) {
        await delegateStore.removeAccessedDelegate(delegate.item.id, 'phone_number');
      }

      if (isSetAsPrimary.value && identifier.value) {
        await userStore.updatePhoneNumber(identifier.value, false);
        await delegateStore.setAccessedDelegates();
      }
    }

    if (isRevokeAccess.value && delegate?.item?.id) {
      const id = delegate.item.id;

      if (isRevokeSearch.value) await delegateStore.revokeSearchSharedDelegate(id);
      if (!isRevokeSearch.value) await delegateStore.revokeSharedDelegate(id);
    }

    isExecuting.value = false;
  };

  const resetState = () => {
    removeDelegate();
    removeIdentifier();
    resetAction();
    resetType();
    resetIsExecuting();
    resetIsVerified();
    resetIsRevokeSearch();
  };

  return {
    isVerified,
    setIsVerified,
    resetIsVerified,
    delegate,
    setDelegate,
    removeDelegate,
    identifier,
    setIdentifier,
    getIdentifier,
    action,
    type,
    setType,
    isPhone,
    isEmail,
    isDelegates,
    addNew,
    newPrimary,
    changePrimary,
    editPrimary,
    setAsPrimary,
    seeSharedAccess,
    removeContact,
    revokeAccess,
    deleteAccount,
    isAddNew,
    isRemove,
    isNewPrimary,
    isChangePrimary,
    isEditPrimary,
    isSetAsPrimary,
    isSeeSharedAccess,
    isRevokeAccess,
    isDeleteAccount,
    resetState,
    isExecuting,
    execute,
  };
});
